import React, {useEffect, useMemo, useRef, useState} from 'react';
import {withRouter} from 'react-router';
import {useSelector} from 'react-redux';
import WT from '@sscale/wtsdk';
import Video from '../Video/Video';
import Footer from '../Footer/Footer';
import Player from '../Player/Player';

import './Session.css'


const Session = ({history}) => {
    const {mscURL, token, userName, sessionId} = useSelector(state => state.participant);
    const [participants, setParticipants] = useState([]);
    const [connectedToSession, setConnectedToSession] = useState(false);
    const participantsRef = useRef(participants);

    useEffect(() => {
        return () => {
            WT.Session.disconnect();
        }
    }, [])

    useEffect(() => {
        if (!mscURL || !token || !userName || !sessionId) {
            return
        }

        const sessionSettings = {
            wsUrl: mscURL,
            sessionToken: token,
            sessionId
        };

        const userData = {
            displayed_name: userName
        };

        WT.Session.connect(sessionSettings, userData);

    }, [mscURL, token, userName, sessionId]);

    useEffect(() => {
        participantsRef.current = participants;
    }, [participants]);


    useEffect(() => {
        WT.SessionListeners.onConnected((exParticipants) => {

            setConnectedToSession(true);

            WT.SessionListeners.onStreamCreated((params) => {

                const {participantId} = params;

                participantsRef.current = participantsRef.current.filter(participant => participant.participantId !== participantId);

                setParticipants([...participantsRef.current, params])
            });

            WT.ParticipantListeners.onParticipantMediaStreamChanged((params) => {
                const alreadyExists = participantsRef.current.find((p) => p.participantId === params.participantId);
                if (!alreadyExists) {
                    setParticipants([...participantsRef.current, params])
                }
            })

            WT.ParticipantListeners.onParticipantLeft(({participant}) => {
                setParticipants(participantsRef.current.filter(p => p.participantId !== participant.id));
            });

        });
    }, []);


    useEffect(() => {

        const updateWindowDimensions = () => {
        }

        window.addEventListener('resize', updateWindowDimensions);
        updateWindowDimensions();
        return () => window.removeEventListener('resize', updateWindowDimensions)

    }, [window.innerWidth, window.innerHeight, participants])

    const memoizedRenderParticipant = useMemo(() => {
        return (participants.map(participant => {
            return (
                <Video key={participant.participantId} participant={participant}/>
            )
        }))
    }, [participants]);

    return (
        <div className='session-container'>
            <div className='main-stage'>
              <iframe width="1080" height="720" src="https://www.youtube.com/embed/odM92ap8_c0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
            <div className='session-videos'>

                <div id='videos' className='video-spaces'>
                    <div id='video-containers'>
                        {memoizedRenderParticipant}
                    </div>

                </div>

            </div>
            <Footer localParticipant={participants.filter(p => !!p.local)[0]}
                    participantPublished={true}
                    connectedToSession={connectedToSession}
                    participants={participants}
            />
        </div>)
};

export default withRouter(Session)
