import React, {useEffect, useState} from 'react';
import classnames from 'classnames';
import Slider from '@material-ui/core/Slider';
import WT from '@sscale/wtsdk';
import './VideoQuality.css'

const VideoQuality = ({showModal, show}) => {
    const [videoWidth, setVideoWidth] = useState(480);
    const [videoHeight, setVideoHeight] = useState(640);
    const [frameRate, setFrameRate] = useState(15);

    const handleChangeWidth = (event, newValue) => {
        setVideoWidth(newValue);
    };

    const handleChangeHeight = (event, newValue) => {
        setVideoHeight(newValue);
    };

    const handleChangeFrameRate = (event, newValue) => {
        setFrameRate(newValue);
    };

    const setVideoQuality = () => {
        WT.Participant.setMediaQuality({videoWidth, videoHeight, frameRate})
        showModal(false)
    }

    const settingsClasses = classnames({
        'video-quality-container': true,
    })

    if(!show) {
        return (<div></div>)
    }

    return (<>
        <div className='video-quality'>
        <div className={settingsClasses}>
            <div className='video-width-container'>
                <div className='title-text'>Width pxls: {videoWidth}</div>
                <div id='discrete-slider-width' className='slider-container'>
                    <Slider
                        min={144}
                        max={1280}
                        step={null}
                        value={videoWidth}
                        marks={[{value: 144}, {value: 176}, {value: 240},
                            {value: 320}, {value: 480}, {value: 640},
                            {value: 720}, {value: 1024}, {value: 1280}]}
                        onChange={handleChangeWidth}
                        aria-labelledby='discrete-slider-width'/>
                </div>
            </div>

            <div className='video-width-container'>
                <div className='title-text'>Height pxls: {videoHeight}</div>
                <div id='discrete-slider-restrict' className='slider-container'>
                    <Slider
                        min={144}
                        max={1280}
                        step={null}
                        value={videoHeight}
                        marks={[{value: 144}, {value: 176}, {value: 240},
                            {value: 320}, {value: 480}, {value: 640},
                            {value: 720}, {value: 1024}, {value: 1280}]}
                        onChange={handleChangeHeight}
                        aria-labelledby='discrete-slider-restrict'/>
                </div>
            </div>

            <div className='video-width-container'>
                <div className='title-text'>Frame Rate: {frameRate}</div>
                <div className='slider-container'>
                    <Slider min={10}
                            max={60}
                            step={5}
                            value={frameRate} onChange={handleChangeFrameRate} aria-labelledby="continuous-slider"/>
                </div>
            </div>

            <div className='video-quality-footer-buttons'>
                <button onClick={setVideoQuality}>Apply</button>
                <button onClick={() => showModal(false)}>Close</button>
            </div>
        </div>
        </div>
    </>)
}

export default VideoQuality;