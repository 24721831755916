import apiRequest from '../../Http';

const SET_ROOM_SETTINGS = 'SET_ROOM_SETTINGS';
const RESET_STORE = 'RESET_STORE';

const initialState = {
  mscURL: '',
  token: '',
  userName: '',
  sessionId: '',
};

function login(userName, sessionId) {
  return async (dispatch, getState) => {
    try {
      const response = await apiRequest.post('/token/', {
        room_name: sessionId
      });

      const data = JSON.parse(atob(response.data.streaming_token.split('.')[1]));

      dispatch({
        type: SET_ROOM_SETTINGS,
        payload: {mscURL: 'wss://' + data.sigress, unct: data.unct, token: response.data.streaming_token, userName, sessionId}
      })
    } catch (e) {
      console.error('Error: ', e);
    }
  }
}

function resetStore() {
  return {
    type: RESET_STORE,
    payload: {
      token: '',
      username: '',
    }
  }
}

export {
  login,
  resetStore,
}

const ACTION_HANDLERS = {
  [SET_ROOM_SETTINGS]: (state, action) => {
    const {mscURL, token, userName, sessionId} = action.payload;
    return {...state, mscURL, token, userName, sessionId}
  },

  [RESET_STORE]: (state, action) => {
    return {...state, ...action.payload}
  }
};

export default function ParticipantReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state
}
