import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {withRouter} from 'react-router';
import Login from '../Login/Login';
import Session from '../Session/Session';
import './App.css';

function App({}) {
    return (<div className='app-container'>
            <div className='powered-by-container'>
                <div className='powered-by-text'>Powered by</div>
                <div className='logo-top'>
                </div>

            </div>

            <Switch>
                <Route path='/' component={Login} exact/>
                <Route path='/session' component={Session} exact/>
            </Switch>

        </div>
    );
}

export default withRouter(App);
