import React from 'react';
import { Player } from 'bitmovin-player';
import { UIFactory } from 'bitmovin-player/bitmovinplayer-ui';
import 'bitmovin-player/bitmovinplayer-ui.css';

class BitmovinPlayer extends React.Component {

    state = {
        player: null,
    };

    playerConfig = {
        key: '1c463e0e-fd31-47f8-baf3-b15340638ac5',
        ui: false
    };

    playerSource = {
        hls: 'https://liveproduseast.global.ssl.fastly.net/btv/desktop/us_live.m3u8',
        poster: ''
    };

    constructor(props) {
        super(props);
        this.playerDiv = React.createRef();
    }

    componentDidMount() {
        this.setupPlayer();
    }

    componentWillUnmount() {
        this.destroyPlayer();
    }

    setupPlayer() {
        const player = new Player(this.playerDiv.current, this.playerConfig);
        UIFactory.buildDefaultUI(player);
        player.load(this.playerSource).then(() => {
            this.setState({
                ...this.state,
                player
            });
            console.log('Successfully loaded source');
            document.getElementById("bitmovinplayer-video-player").play();
        }, () => {
            console.log('Error while loading source');
        });
    }

    destroyPlayer() {
        if (this.state.player != null) {
            this.state.player.destroy();
            this.setState({
                ...this.state,
                player: null
            });
        }
    }

    render() {
        return <div id='player' ref={this.playerDiv}/>;
    }
}

export default BitmovinPlayer;