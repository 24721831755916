import React, {useEffect, useState} from 'react';
import {withRouter} from 'react-router';
import {useDispatch} from 'react-redux';
import {login, resetStore} from '../../Store/Reducers/ParticipantReducer';
import './Login.css'

const Login = ({history}) => {
    const [participantName, setParticipantName] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const onChangeParticipantName = (e) => {
        setParticipantName(e.target.value)
    };

    useEffect(() => {
        dispatch(resetStore())
    }, [dispatch]);

    const joinToConference = (e) => {
        e.preventDefault();

        setLoading(true);
        dispatch(login(participantName, 'my-example-app'));
        setLoading(false);
        history.push('/session');
    };

    return (<div className='login-container'>
        <div className='login-block'>
            <form className='login-form'
                  onSubmit={joinToConference}>
                <label htmlFor='participantName'>Enter participant name:</label>

                <input
                    name='participantName'
                    placeholder={'Participant Name'}
                    className='login-field'
                    autoFocus
                    autoComplete='off'
                    value={participantName}
                    onChange={onChangeParticipantName}
                />

                <button
                    type='submit'
                    disabled={!participantName.length || loading}
                    className='join-conference-button'>JOIN SESSION</button>
            </form>
        </div>
    </div>)
}

export default withRouter(Login)
