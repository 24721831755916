import axios from 'axios';

const apiRequest = axios.create({
    baseURL: process.env.REACT_APP_CLUSTER_URL,
    headers: {
        'Content-Type': 'application/json',
        'Auth-Token': process.env.REACT_APP_AUTH_TOKEN
    }
});

export default apiRequest