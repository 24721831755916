import React, {useEffect, useRef, useState} from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import WT from '@sscale/wtsdk';
import classnames from 'classnames'
import {withRouter} from 'react-router';
import './Footer.css';
import {resetStore} from '../../Store/Reducers/ParticipantReducer';
import {useDispatch, useSelector} from 'react-redux';
import Timer from "../Timer/Timer";
import Chat from '../Chat/Chat';
import VideoQuality from "../VideoQuality/VideoQuality";

const Footer = ({history, localParticipant, participantPublished, participants, connectedToSession}) => {
    const {sessionId} = useSelector(state => state.participant)
    const [audioEnabled, setAudioEnabled] = useState(true);
    const [videoEnabled, setVideoEnabled] = useState(true);
    const [userShareScreen, setUserShareScreen] = useState(false);
    const [showVideoQualityModal, setShowVideoQualityModal] = useState(false);
    const dispatch = useDispatch();
    const screenSharingRef = useRef();
    const leaveCallRef = useRef();

    useEffect(() => {
        if (localParticipant) {
            if (audioEnabled) {
                const elem = document.getElementById(`${localParticipant.participantId}-muted-microphone`);

                if (elem) {
                    elem.style.display = 'none'
                }
            } else {
                const elem = document.getElementById(`${localParticipant.participantId}-muted-microphone`);

                if (elem) {
                    elem.style.display = 'block'
                }
            }
        }
    }, [audioEnabled]);

    useEffect(() => {
        const keyboardListener = (e) => {

            if (document.activeElement.tagName === 'TEXTAREA') {
                return
            }

            if (e.keyCode === 77) { // M
                toggleAudio();
            }

            if (e.keyCode === 86) { // V
                toggleVideo()
            }


            if (e.keyCode === 76) { // L
                leaveCallRef.current();
            }

            if (e.keyCode === 83) { // S
                screenSharingRef.current();
            }

        }

        document.addEventListener('keyup', keyboardListener, false);

        return () => {
            document.removeEventListener('keyup', keyboardListener, false)
        }
    }, [])

    useEffect(() => {
        if (localParticipant) {
            if (videoEnabled) {
                const avatar = document.getElementById(`${localParticipant.participantId}-show-avatar`);
                const elem = document.getElementById(`${localParticipant.participantId}-muted-video`);

                if (elem) {
                    elem.style.display = 'none'
                }

                if (avatar) {
                    avatar.style.display = 'none';
                }
            } else {
                const avatar = document.getElementById(`${localParticipant.participantId}-show-avatar`);
                const elem = document.getElementById(`${localParticipant.participantId}-muted-video`);

                if (elem) {
                    elem.style.display = 'block'
                }

                if (avatar) {
                    avatar.style.display = 'block';
                }
            }
        }
    }, [videoEnabled, localParticipant])

    useEffect(() => {
        if (localParticipant) {
            const elem = document.getElementById(`video-${localParticipant.participantId}`);

            if (elem) {
                if (userShareScreen) {
                    elem.classList.add('screen-share-video')
                } else {
                    elem.classList.remove('screen-share-video')
                }
            }
        }
    }, [userShareScreen, localParticipant])

    const toggleMenu = () => {

    }

    const toggleAudio = () => {
        if (WT.Participant.isAudioEnabled()) {
            WT.Participant.disableAudio();
            setAudioEnabled(WT.Participant.isAudioEnabled());
        } else {
            WT.Participant.enableAudio();
            setAudioEnabled(WT.Participant.isAudioEnabled());
        }
    };

    const toggleVideo = () => {
        if (WT.Participant.isVideoEnabled()) {
            WT.Participant.disableVideo();
            setVideoEnabled(WT.Participant.isVideoEnabled());
        } else {
            WT.Participant.enableVideo();
            setVideoEnabled(WT.Participant.isVideoEnabled());
        }
    };

    const toggleScreenSharing = async () => {

        try {
            if (!userShareScreen) {
                await WT.Participant.startScreenSharing('video-' + localParticipant.participantId);
                setUserShareScreen(true)
            } else {
                WT.Participant.stopScreenSharing('video-' + localParticipant.participantId);
                setUserShareScreen(false)
            }

        } catch (e) {
            setUserShareScreen(false)
        }
    }

    screenSharingRef.current = toggleScreenSharing;

    const leaveCall = () => {
        WT.Session.disconnect();
        dispatch(resetStore());
        history.replace(`/`)
    }

    leaveCallRef.current = leaveCall;

    const audioClasses = classnames({
        'toolbox-icon': true,
        toggled: !audioEnabled
    })

    const videoClasses = classnames({
        'toolbox-icon': true,
        toggled: !videoEnabled
    })

    const screenShareClasses = classnames({
        'toolbox-control': true,
        toggled: userShareScreen
    })

    return (<>
        <div className='toolbox'>
            <div className='toolbox-background'>
            </div>
            <div className='toolbox-content'>
                <div className='button-group-left'>

                </div>

                <div className='button-group-center'>
                    <div className='settings-button-container'>

                        <Tooltip title={<div className='tooltip-box'>Mute / Unmute (M)</div>} placement='top'>
                            <div onClick={toggleAudio} className='toolbox-button'>
                                <div className={audioClasses}>
                                    <div className={audioEnabled ? 'microphone' : 'microphone-muted'}>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>

                        <Tooltip title={<div className='tooltip-box'>Leave call (L)</div>} placement='top'>
                            <div onClick={leaveCall} className='toolbox-button'>
                                <div className='toolbox-icon'>
                                    <div className='leave'>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>

                        <Tooltip title={<div className='tooltip-box'>Start / Stop camera (V)</div>} placement='top'>
                            <div onClick={toggleVideo} className='toolbox-button'>
                                <div className={videoClasses}>
                                    <div className={videoEnabled ? 'camera' : 'camera-stopped'}>
                                    </div>
                                </div>
                            </div>
                        </Tooltip>
                    </div>
                </div>

                <div className='button-group-right'>
                    {participantPublished ? (<div className='toolbox-box' style={{marginRight: '10px'}}>
                        <Timer/>
                    </div>) : null}
                    {participantPublished && connectedToSession ? (
                        <div className='toolbox-button'>
                            <Chat participants={participants}/>
                        </div>
                    ) : null}
                    <div className='toolbox-button' onClick={toggleMenu}>
                        <div className='footer-menu'>
                        </div>
                        <div className='footer-control-menu-container'>
                            <div onClick={() => setShowVideoQualityModal(!showVideoQualityModal)}
                                 className='menu-item'>Change Video Resolution
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <VideoQuality show={showVideoQualityModal} showModal={setShowVideoQualityModal}/>
    </>)
}

export default withRouter(Footer)
