import React, {useEffect, useMemo, useRef, useState} from 'react';
import WT from '@sscale/wtsdk';
import classnames from 'classnames';
import './Video.css'
import Tooltip from '@material-ui/core/Tooltip';
import {MediaStates, MediaTypes, qualityConstants} from "../../constants";

const Video = ({participant}) => {
    const videoRef = useRef();
    const [isRemoteVideoEnabled, setIsRemoteVideoEnabled] = useState(true);
    const [isRemoteAudioEnabled, setIsRemoteAudioEnabled] = useState(true);
    const isRemoteVideoEnabledRef = useRef(isRemoteVideoEnabled);
    const isRemoteAudioEnabledRef = useRef(isRemoteAudioEnabled);

    const videoClasses = classnames({
        'local': participant.local,
        'video-container': true,
    });

    useEffect(() => {
        WT.Session.enableStats();

        WT.SessionListeners.onMosReport(({participantId, stats}) => {

            if(participant.participantId === participantId) {
                document.getElementById(`${participantId}-network-video`).innerText = stats.video ? stats.video: 'N/A';
                document.getElementById(`${participantId}-network-audio`).innerText = stats.audio ? stats.audio : 'N/A';
                document.getElementById(`${participantId}-network-rtt`).innerText = stats.RTT ? stats.RTT: 'N/A';
                document.getElementById(`${participantId}-network-frameRate`).innerText = (stats.videoStat && stats.videoStat.frameRate) ? stats.videoStat.frameRate : 'N/A';

                if(stats.video === qualityConstants.bad || stats.audio === qualityConstants.bad) {
                    document.getElementById(`${participant.participantId}-connection-container`).style.backgroundColor = 'red'
                } else {
                    document.getElementById(`${participant.participantId}-connection-container`).style.backgroundColor = '#2dc937'
                }
            }
        })
    }, [])

    useEffect(() => {
        isRemoteAudioEnabledRef.current = isRemoteAudioEnabled
    }, [isRemoteAudioEnabled])

    useEffect(() => {
        isRemoteVideoEnabledRef.current = isRemoteVideoEnabled
    }, [isRemoteVideoEnabled])

    useEffect(() => {
        WT.ParticipantListeners.onParticipantSpeaking(({participantId}) => {
            if (participant.participantId === participantId) {
                const elem = document.getElementById(participantId);

                if (elem) {
                    elem.classList.add('active-speaker')
                }
            }
        })

        WT.ParticipantListeners.onParticipantStopSpeaking(({participantId}) => {
            if (participant.participantId === participantId) {
                const elem = document.getElementById(participantId);

                if (elem) {
                    elem.classList.remove('active-speaker')
                }
            }
        })

        WT.ParticipantListeners.onParticipantMediaStreamChanged(({participantId, mediaState, mediaType}) => {
            if (participant.participantId === participantId) {
                if (mediaType === MediaTypes.AUDIO && isRemoteAudioEnabledRef.current) {
                    if (mediaState === MediaStates.DISABLED) {
                        document.getElementById(`${participant.participantId}-muted-microphone`).style.display = 'block';
                    } else {
                        document.getElementById(`${participant.participantId}-muted-microphone`).style.display = 'none';
                    }
                } else if (mediaType === MediaTypes.VIDEO && isRemoteVideoEnabledRef.current) {
                    if (mediaState === MediaStates.DISABLED) {
                        const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
                        const elem = document.getElementById(`${participant.participantId}-muted-video`);

                        if (elem) {
                            elem.style.display = 'block'
                        }

                        if (avatar) {
                            avatar.style.display = 'block';
                        }
                    } else {
                        const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
                        const elem = document.getElementById(`${participant.participantId}-muted-video`);

                        if (elem) {
                            elem.style.display = 'none'
                        }

                        if (avatar) {
                            avatar.style.display = 'none';
                        }
                    }
                }
            }
        })
    }, [])

    useEffect(() => {
        const toggleFullscreen = e => {
            if (!document.fullscreenElement) {
                if (e.target.requestFullscreen) {
                    e.target.requestFullscreen();
                } else if (e.target.mozRequestFullScreen) { /* Firefox */
                    e.target.mozRequestFullScreen();
                } else if (e.target.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                    e.target.webkitRequestFullscreen();
                } else if (e.target.msRequestFullscreen) { /* IE/Edge */
                    e.target.msRequestFullscreen();
                }

                videoRef.current.controls = false;
            } else {
                document.exitFullscreen();
                videoRef.current.controls = false;
            }
        };
        videoRef.current.srcObject = participant.streams[0];
        videoRef.current.ondblclick = toggleFullscreen;
    }, []);

    const memoizedVideo = useMemo(() => {
        return (<div id={`videospace-${participant.participantId}`} className='video-space'>
            <div className='top-bar'>
                <div id={`${participant.participantId}-connection-container`} className='connection-container'>
                    <div className='connection-image'>
                    </div>
                </div>

                <div className='network-monitoring-container'>
                    <div className='network-item'>
                        <div>Video State:</div>
                        <div id={`${participant.participantId}-network-video`} className='network-value'>N/A</div>
                    </div>

                    <div className='network-item'>
                        <div>Audio State: </div>
                        <div id={`${participant.participantId}-network-audio`} className='network-value'>N/A</div>
                    </div>

                    <div className='network-item'>
                        <div>RTT: </div>
                        <div id={`${participant.participantId}-network-rtt`} className='network-value'>N/A</div>
                    </div>

                    <div className='network-item'>
                        <div>Frame Rate: </div>
                        <div id={`${participant.participantId}-network-frameRate`} className='network-value'>N/A</div>
                    </div>
                </div>
            </div>

            <div id={`${participant.participantId}-show-avatar`} className='video-container-background'>
                <div className='avatar-image'>
                    <img src={'/avatar.png'} alt='avatar'/>
                </div>
            </div>

            <div className='video-container-avatar-only'>
            </div>

            <div className='user-indicator-container'>
                <div className='indicator-container'>
                    <Tooltip aria-label='muted' title={<div className='tooltip-box'>Participant is muted</div>}
                             placement='top'>
                        <div className='muted-audio-container' id={`${participant.participantId}-muted-microphone`}>
                            <div className='muted-audio'>
                            </div>

                        </div>
                    </Tooltip>

                    <Tooltip title={<div className='tooltip-box'>Participant has stopped the camera</div>}
                             placement='top'>
                        <div id={`${participant.participantId}-muted-video`} className='muted-video-container'>
                            <div className='muted-video'>
                            </div>
                        </div>
                    </Tooltip>
                </div>
                <div>{participant.participantName}</div>
            </div>

            <video
                className={participant.local ? 'flipVideoX' : ''}
                id={`video-${participant.participantId}`}
                ref={videoRef}
                playsInline
                autoPlay
                disablePictureInPicture
                muted={participant.local}
            >
            </video>
        </div>)
    }, [participant])

    const removeParticipant = () => {
        WT.Participant.disconnectRemoteParticipant(participant.participantId);
    }

    const toggleRemoteVideo = () => {
        WT.Participant.toggleRemoteVideo(participant.participantId);
        if(!WT.Participant.isRemoteVideoEnabled(participant.participantId)) {
            const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
            const elem = document.getElementById(`${participant.participantId}-muted-video`);

            if (elem) {
                elem.style.display = 'block'
            }

            if (avatar) {
                avatar.style.display = 'block';
            }

            setIsRemoteVideoEnabled(false)
        } else {
            const avatar = document.getElementById(`${participant.participantId}-show-avatar`);
            const elem = document.getElementById(`${participant.participantId}-muted-video`);

            if (elem) {
                elem.style.display = 'none'
            }

            if (avatar) {
                avatar.style.display = 'none';
            }

            setIsRemoteVideoEnabled(true)
        }
    }

    const toggleRemoteAudio = () => {
        WT.Participant.toggleRemoteAudio(participant.participantId);

        if(!WT.Participant.isRemoteAudioEnabled(participant.participantId)) {
            document.getElementById(`${participant.participantId}-muted-microphone`).style.display = 'block';

            setIsRemoteAudioEnabled(false);
        } else {
            document.getElementById(`${participant.participantId}-muted-microphone`).style.display = 'none';

            setIsRemoteAudioEnabled(true);
        }
    }

    return (
        <div id={participant.participantId} className={videoClasses}>

            {!participant.local ? (<>
                <div className='user-control'>
                </div>

                <div className='user-control-menu-container'>
                    <div className='menu-item' onClick={toggleRemoteAudio}>
                        {isRemoteAudioEnabled ? (<>
                        <img src='microphone-muted.svg' alt='mute'/>
                        Mute for myself
                    </>) : <>
                            <img src='microphone-white.svg' alt='mute'/>
                            Unmute for myself
                        </>}
                    </div>
                    <div className='menu-item' onClick={toggleRemoteVideo}>
                        {isRemoteVideoEnabled ? (<>
                            <img src='camera-stopped.svg' alt='mute-video'/>
                            Turn off video
                        </>): (<>
                            <img src='camera-white.svg' alt='mute-video'/>
                            Turn on video
                        </>)}
                    </div>


                </div>
            </>) : null}

            {memoizedVideo}
        </div>
    )
};

export default Video
